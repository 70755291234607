import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Menu, Layout, Input, Divider } from 'antd';
import {
  SearchOutlined,
  BookOutlined,
  HomeOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UploadOutlined,
  DownloadOutlined,
  LogoutOutlined,
  UserOutlined,
  ProfileOutlined,
  PercentageOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Routes } from 'src/router/Routes.helper';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useAccounts } from 'src/modules/accounts/provider/accounts.provider';
import mcubeLogo from 'src/images/logo-mcube.png';
import defaultProfilePic from 'src/images/profile-pic-default.png';
import { AppVersion } from '../AppVersion';
import { ScopeSelector } from '../ScopeSelector';
import { checkIfCurrentPage } from 'src/utils/utils';

const MenuItem = ({ to, children, ...rest }) => {
  const location = useLocation();

  useEffect(() => {
    const isActive = location.pathname.startsWith(to);
    const link = document.querySelector(`[href='${to}']`);
    if (!link) return;
    const li = link.closest('li');
    if (!li) return;
    isActive
      ? li.classList.add('ant-menu-item-active', 'ant-menu-item-selected', 'hover')
      : li.classList.remove('ant-menu-item-active', 'ant-menu-item-selected');
  }, [location.pathname, to]);

  return (
    <Menu.Item {...rest}>
      <Link
        to={to}
        className={to === location.pathname ? 'pointer-events-none' : ''}
      >
        {children}
      </Link>
    </Menu.Item>
  );
};

export const SideBar = () => {
  const { t } = useTranslation();
  const text = textFrom('components.sidebar', t);

  const { userInfo } = useAccounts();
  const location = useLocation();
  const history = useHistory();
  const { signOut, checkIfUserHasAuthorities } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [searchedText, setSearchedText] = useState(undefined);
  const searchInputRef = useRef(null);

  const handleSearchChange = (event) => {
    setSearchedText(event.target.value);
  };

  const handleSearch = (product) => {
    setSearchedText(undefined);
    searchInputRef.current.blur();
    history.push({
      pathname: Routes.PATHS.PRODUCTS_FASHION,
      search: `text=${product}`
    });
  };

  const menuItems = [
    {
      title: text('menuItems.home.title'),
      path: Routes.PATHS.DASHBOARD,
      icon: <HomeOutlined />,
      neededAuthorities: []
    },
    {
      title: text('menuItems.catalog.title'),
      key: 'catalog',
      icon: <BookOutlined />,
      neededAuthorities: [],
      children: [
        {
          title: text('menuItems.catalog.children.brands'),
          path: Routes.PATHS.BRANDS,
          neededAuthorities: ['BO_HL_BRAND_MENU']
        },
        {
          title: text('menuItems.catalog.children.macrocategories.title'),
          neededAuthorities: [],
          path: Routes.PATHS.MACROCATEGORY_CREATE,
          children: [
            {
              title: text('menuItems.catalog.children.macrocategories.children.macrocategories'),
              neededAuthorities: ['BO_HL_MACROCATEGORY_MENU'],
              path: Routes.PATHS.MACROCATEGORY_CREATE
            },
            {
              title: text('menuItems.catalog.children.macrocategories.children.microcategories'),
              neededAuthorities: ['BO_HL_MICROCATEGORY_MENU'],
              path: Routes.PATHS.MICROCATEGORY_CREATE
            },
            {
              title: text('menuItems.catalog.children.macrocategories.children.assignment'),
              neededAuthorities: ['BO_HL_MICROCATEGORY_ASSIGN_MENU'],
              path: Routes.PATHS.MICROCATEGORY_ASSIGN
            }
          ]
        },
        {
          title: text('menuItems.collections.title'),
          neededAuthorities: [],
          path: Routes.PATHS.OUTFIT_COLLECTION,
          children: [
            {
              title: text('menuItems.collections.children.collectionGroup.title'),
              neededAuthorities: ['BO_HL_COLLECTION_GROUP_MENU'],
              path: Routes.PATHS.COLLECTION_GROUP
            },
            {
              title: text('menuItems.collections.children.outfitCollection.title'),
              neededAuthorities: ['BO_HL_OUTFIT_COLLECTION_MENU'],
              path: Routes.PATHS.OUTFIT_COLLECTION
            }
          ]
        },
        {
          title: text('menuItems.catalog.children.attributes.title'),
          neededAuthorities: [],
          children: [
            {
              title: text('menuItems.catalog.children.attributes.children.variant'),
              neededAuthorities: ['BO_HL_VARIANT_ATTRIBUTE_MENU'],
              path: Routes.PATHS.VARIANT_ATTRIBUTES
            },
            {
              title: text('menuItems.catalog.children.attributes.children.product'),
              neededAuthorities: ['BO_HL_PRODUCT_ATTRIBUTE_MENU'],
              path: Routes.PATHS.PRODUCT_ATTRIBUTES
            }
          ]
        },
        {
          title: text('menuItems.catalog.children.productsFood'),
          path: Routes.PATHS.PRODUCTS_FOOD,
          neededAuthorities: ['BO_HL_FOOD_PRODUCT_MENU']
        },
        {
          title: text('menuItems.catalog.children.productsFashion'),
          path: Routes.PATHS.PRODUCTS_FASHION,
          neededAuthorities: ['BO_HL_RETAIL_PRODUCT_MENU']
        },
        {
          title: text('menuItems.catalog.children.upcomingEvents'),
          path: Routes.PATHS.PRODUCTS_EVENT_UPCOMING,
          neededAuthorities: ['BO_HL_EVENT_UPCOMING_EVENT_MENU']
        },
        {
          title: text('menuItems.catalog.children.pastEvents'),
          path: Routes.PATHS.PRODUCTS_EVENT_PAST,
          neededAuthorities: ['BO_HL_EVENT_PAST_EVENT_MENU']
        },
        {
          title: text('menuItems.catalog.children.outfits'),
          path: Routes.PATHS.OUTFITS,
          neededAuthorities: ['BO_HL_OUTFIT_MENU']
        },
        {
          title: text('menuItems.locations.children.locations'),
          path: Routes.PATHS.LOCATIONS,
          neededAuthorities: ['BO_HL_LOCATION_MENU']
        },
        {
          title: text('menuItems.catalog.children.visualMerchandising'),
          path: Routes.PATHS.VISUAL_MERCHANDISING,
          neededAuthorities: ['BO_HL_VISUAL_MERCHANDISING_MENU']
        },
        {
          title: text('menuItems.catalog.children.generalSettings'),
          path: Routes.PATHS.GENERAL_SETTINGS,
          neededAuthorities: ['BO_HL_SETTING_MENU']
        }
      ]
    },
    {
      title: text('menuItems.orders.title'),
      key: 'orders',
      icon: <ProfileOutlined />,
      neededAuthorities: [],
      children: [
        {
          title: text('menuItems.orders.children.fashionOrders'),
          path: Routes.PATHS.ORDERS_FASHION,
          neededAuthorities: ['BO_HL_RETAIL_ORDER_MENU']
        },
        {
          title: text('menuItems.orders.children.upcomingFoodOrders'),
          path: Routes.PATHS.UPCOMING_ORDERS_FOOD,
          neededAuthorities: ['BO_HL_FOOD_UPCOMING_ORDER_MENU']
        },
        {
          title: text('menuItems.orders.children.pastFoodOrders'),
          path: Routes.PATHS.PAST_ORDERS_FOOD,
          neededAuthorities: ['BO_HL_FOOD_PAST_ORDER_MENU']
        },
        {
          title: text('menuItems.orders.children.upcomingEventsOrders'),
          path: Routes.PATHS.UPCOMING_ORDERS_EVENT,
          neededAuthorities: ['BO_HL_EVENT_UPCOMING_ORDER_MENU']
        },
        {
          title: text('menuItems.orders.children.pastEventsOrders'),
          path: Routes.PATHS.PAST_ORDERS_EVENT,
          neededAuthorities: ['BO_HL_EVENT_PAST_ORDER_MENU']
        }
      ]
    },
    {
      title: text('menuItems.coupons.title'),
      key: 'coupons',
      path: Routes.PATHS.COUPONS,
      icon: <PercentageOutlined />,
      neededAuthorities: ['BO_HL_COUPON_MENU']
    },
    {
      title: text('menuItems.inventory.title'),
      key: 'inventory',
      icon: <ShoppingCartOutlined />,
      neededAuthorities: [],
      children: [
        {
          title: text('menuItems.inventory.children.warehouses'),
          path: Routes.PATHS.WAREHOUSES,
          neededAuthorities: ['BO_HL_WAREHOUSE_MENU']
        },
        {
          title: text('menuItems.inventory.children.stocks'),
          path: Routes.PATHS.STOCKS,
          neededAuthorities: ['BO_HL_STOCK_MENU']
        }
      ]
    },
    {
      title: text('menuItems.upload.title'),
      path: Routes.PATHS.UPLOADS_LIST,
      icon: <UploadOutlined />,
      neededAuthorities: ['BO_HL_UPLOAD_MENU']
    },
    {
      title: text('menuItems.downloads.title'),
      path: Routes.PATHS.DOWNLOADS,
      icon: <DownloadOutlined />,
      neededAuthorities: ['BO_HL_DOWNLOAD_MENU']
    },
    {
      title: text('menuItems.account.title'),
      path: Routes.PATHS.ACCOUNTS,
      icon: <UserOutlined />,
      neededAuthorities: ['BO_HL_ACCOUNT_MENU']
    },
    {
      title: text('menuItems.customers.title'),
      path: Routes.PATHS.CUSTOMERS,
      icon: <UserOutlined />,
      neededAuthorities: ['BO_HL_CUSTOMER_MENU']
    },
    {
      title: text('menuItems.settings.title'),
      icon: <SettingOutlined />,
      neededAuthorities: [],
      children: [
        {
          title: text('menuItems.settings.children.users'),
          path: Routes.PATHS.USERS,
          neededAuthorities: ['BO_HL_USER_MENU']
        },
        {
          title: text('menuItems.settings.children.permissionSets'),
          path: Routes.PATHS.PERMISSION_SETS,
          neededAuthorities: ['BO_HL_PERMISSION_SET_MENU']
        },
        {
          title: text('menuItems.settings.children.localBusinesses'),
          path: Routes.PATHS.LOCAL_BUSINESSES,
          neededAuthorities: ['BO_HL_LOCALBUSINESS_MENU']
        },
        {
          title: text('menuItems.settings.children.webhooks'),
          path: Routes.PATHS.WEBHOOKS,
          neededAuthorities: ['BO_HL_WEBHOOK_MENU']
        },
        {
          title: text('menuItems.settings.children.flag'),
          path: Routes.PATHS.FLAG,
          neededAuthorities: ['BO_HL_FEATURE_FLAGS_CONFIG_MENU']
        },
        {
          title: text('menuItems.settings.children.languages'),
          path: Routes.PATHS.LANGUAGES,
          neededAuthorities: ['BO_HL_LANGUAGE_MENU']
        }
      ]
    },
    {
      title: text('menuItems.lookbook.title'),
      icon: <NotificationOutlined />,
      neededAuthorities: [],
      children: [
        {
          title: text('menuItems.lookbook.children.lookAndFeel'),
          path: Routes.PATHS.LOOKBOOK_CONFIG,
          neededAuthorities: ['BO_HL_LOOKBOOK_LOOK_AND_FEEL_MENU']
        },
        {
          title: text('menuItems.lookbook.children.homepageSetup'),
          path: Routes.PATHS.LOOKBOOK_HOMEPAGE_SETUP,
          neededAuthorities: ['BO_HL_LOOKBOOK_HOMEPAGE_SETUP_MENU']
        }
      ]
    },
    {
      title: text('menuItems.present.title'),
      icon: <NotificationOutlined />,
      neededAuthorities: [],
      children: [
        {
          title: text('menuItems.present.children.lookAndFeel'),
          path: Routes.PATHS.PRESENTATION_CONFIG,
          neededAuthorities: ['BO_HL_PRESENT_LOOK_AND_FEEL_MENU']
        }
      ]
    }
  ];

  const openedMenuItems = useMemo(() => {
    const result = menuItems.find(({ children }) => {
      if (!children) return false;
      return children.find(({ path }) => path === location.pathname);
    });
    return result ? [result.key] : [];
  }, [location.pathname, menuItems]);

  const renderMenuItem = (item) => (
    <MenuItem key={item.path} to={item.path} icon={item.icon}>
      {item.title}
    </MenuItem>
  );

  const navigateToUserPage = () => {
    history.push(Routes.PATHS.USER);
  };

  const getAuthorizedSubItems = (items) => {
    const authorizedSubItems = items.filter(subItem => {
      if (!subItem.children) {
        return checkIfUserHasAuthorities(subItem.neededAuthorities);
      } else {
        const authorizedSubSubItems = getAuthorizedSubItems(subItem.children);
        return (authorizedSubSubItems.length > 0);
      }
    });
    return authorizedSubItems;
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      if (!item.children) {
        // Leaf - check authorities and, if matching, render menu item
        if (checkIfUserHasAuthorities(item.neededAuthorities)) {
          return renderMenuItem(item);
        }
      } else {
        /*
        * Parent - check recursively among its childrens for leaf nodes.
        * A Parent node has to be shown only if at least one of it's child
        * nodes match requested permission
        */
        const authorizedSubItems = getAuthorizedSubItems(item.children);
        if (authorizedSubItems.length > 0) {
          return (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.title}>
              {renderMenuItems(authorizedSubItems)}
            </Menu.SubMenu>
          );
        }
      }
      return null;
    });
  };

  const ProfilePic = ({ imgSrc }) => (
    <img
      width='30px'
      height='30px'
      src={imgSrc || defaultProfilePic}
    />
  );

  const Username = ({ firstName, lastName }) => (
    <div className='truncate-with-ellipsis ml-2'>
      {`${firstName || ''} ${lastName || ''}`}
    </div>
  );

  return (
    <Layout.Sider
      width={250}
      theme='light'
      className='pt-6'
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className='px-4 pb-2'>
        <img
          src={userInfo?.backofficeIcon?.imageURL ?? mcubeLogo}
          alt='Logo'
        />
      </div>
      {collapsed &&
        <div className='mb-2 px-8'>
          <SearchOutlined onClick={() => setCollapsed(false)} />
        </div>}
      {
        !collapsed && (
          <div className='mb-6 px-4 side-searchbox'>
            <Input.Search
              disabled={location.pathname === Routes.PATHS.PRODUCTS_FASHION}
              ref={searchInputRef}
              value={searchedText}
              onSearch={handleSearch}
              onChange={handleSearchChange}
              placeholder={text('searchPlaceholder')}
            />
          </div>
        )
      }
      <Menu
        className='main-menu'
        defaultSelectedKeys={[Routes.PATHS.DASHBOARD]}
        defaultOpenKeys={openedMenuItems}
        selectedKeys={[location.pathname]}
        mode='inline'
      >
        {renderMenuItems(menuItems)}
      </Menu>
      <Menu mode='inline' selectable={false}>
        <Divider />
        {
          userInfo && (
            <>
              <Menu.Item
                onClick={navigateToUserPage}
                className={`sidebar-username ${checkIfCurrentPage(Routes.PATHS.USER) ? 'active' : ''}`}
                icon={<ProfilePic />}
              >
                <Username
                  firstName={userInfo.givenName}
                  lastName={userInfo.familyName}
                />
              </Menu.Item>
              <Menu.Item className='localbusiness-selector'>
                <ScopeSelector userInfo={userInfo} />
              </Menu.Item>
            </>
          )
        }
        <Menu.Item onClick={signOut} key='6' icon={<LogoutOutlined />}>
          {text('logout')}
        </Menu.Item>
      </Menu>
      {!collapsed &&
        <div className='mx-6'>
          <AppVersion />
        </div>}
    </Layout.Sider>
  );
};
